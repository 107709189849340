import React from 'react';
import ScreeningTest from './index';

const Welcome = (props) => {
  return (
    <>
      <ScreeningTest params={props.params}/>
    </>
  );
};

export default Welcome;
